<template>
  <div style="margin-top: 30px;">
    <el-form
      ref="contentForm"
      :rules="rules"
      :model="contentForm"
      label-position="top"
      size="large"
      v-loading="isLoading"
      class="form-container"
      style="padding-left: 0.7rem"
    >
      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item :label="__('Service Provider')" prop="sp_id">
            <el-select
              value-key="sp_id"
              v-model="contentForm.sp_id"
              :placeholder="__('Select')"
              style="width: 100%"
              @change="handleSpSelection"
              filterable
              default-first-option
            >
              <el-option
                v-for="serviceProvider in this.serviceProviders"
                :key="serviceProvider.sp_id"
                :label="serviceProvider.sp_name"
                :value="serviceProvider.sp_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-form-item :label="__('Select Account')" prop="ac_id">
            <el-select
              value-key="ac_id"
              v-model="contentForm.ac_id"
              :placeholder="__('Select')"
              style="width: 100%"
              @change="handleAcSelection"
              filterable
              default-first-option
            >
              <el-option
                v-for="account in this.selectedSpAccounts"
                :key="account.ac_id"
                :label="account.ac_name"
                :value="account.ac_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row type="flex">
        <el-col :span="12" :offset="0">
          <el-row style="height: 30px;">
            <el-form-item :label="__('Move Account to:')"> </el-form-item>
          </el-row>
          <el-row>
            <span style="font-size: small">
              <!-- eslint-disable-next-line -->
              {{ __("You can unassign account and task together or unmap task only.") }}
              <br />

              <!-- eslint-disable-next-line -->
              {{ __("Note: If you want to bulk assign to account or task please do not select any action from below.") }}
            </span>
          </el-row>
        </el-col>
      </el-row>
      <el-row type="flex" style="margin-top:20px;">
        <el-col :span="12" :offset="0">
          <el-form-item :label="__('Service Provider')" prop="to_sp_id">
            <el-select
              value-key="to_sp_id"
              v-model="contentForm.to_sp_id"
              :placeholder="__('Select')"
              style="width: 100%"
              @change="handleToSpSelection"
              filterable
              default-first-option
            >
              <el-option
                v-for="serviceProvider in this.serviceProviders"
                :key="serviceProvider.sp_id"
                :label="serviceProvider.sp_name"
                :value="serviceProvider.sp_id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <div class="h-full">
      <el-tabs
        style="padding-left: 0.7rem; padding-right: 0.7rem"
        v-model="activeSection"
      >
        <el-tab-pane :label="__('Account Plans')" name="ac-plans">
          <el-scrollbar :native="true">
            <div class="contentListContainer">
              <el-table
                ref="acPlanTable"
                v-loading="spPlanLoading"
                :data="acAssignedPlans"
                class="list-table"
              >
                <el-table-column :label="__('Business Plan Name')">
                  <template slot-scope="scope">
                    <span>{{ scope.row.bp_name }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="__('SP Plan Name')">
                  <template slot-scope="scope">
                    <span>{{ scope.row.sp_plan_name }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="__('Plan Type')">
                  <template slot-scope="scope">
                    <span>{{ scope.row.plan_type }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane :label="__('Service Provider Plans')" name="sp-plans">
          <el-scrollbar :native="true">
            <div class="contentListContainer">
              <el-table
                ref="acPlanTable"
                v-loading="spPlanLoading"
                :data="spAssignedPlans"
                class="list-table"
              >
                <el-table-column :label="__('Plan Name')">
                  <template slot-scope="scope">
                    <span>{{ scope.row.sp_plan_name }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="__('Plan Type')">
                  <template slot-scope="scope">
                    <span>{{ scope.row.plan_type }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane :label="__('Trunks')" name="trunks">
          <el-scrollbar :native="true">
            <div class="contentListContainer">
              <el-table
                ref="acPlanTable"
                v-loading="loading"
                :data="selectedSpTrunks"
                class="list-table"
              >
                <el-table-column :label="__('Trunk Name')">
                  <template slot-scope="scope">
                    <span>{{ scope.row.trunk_name }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="__('Trunk Code')">
                  <template slot-scope="scope">
                    <span>{{ scope.row.trunk_code }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="__('Registration Type')">
                  <template slot-scope="scope">
                    <span>{{ scope.row.registration_type }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane :label="__('Password Policies')" name="password-policies">
          <el-scrollbar :native="true">
            <div class="contentListContainer">
              <el-table
                ref="acPlanTable"
                v-loading="loading"
                :data="policies"
                class="list-table"
              >
                <el-table-column :label="__('Policy Name')">
                  <template slot-scope="scope">
                    <span>{{ scope.row.policy_name }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="__('Summary')">
                  <template slot-scope="scope">
                    <span>{{ scope.row.policy_summary }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane :label="__('SP API Keys')" name="sp-api-keys">
          <el-scrollbar :native="true">
            <div class="contentListContainer">
              <el-table
                ref="acPlanTable"
                v-loading="spLoading"
                :data="selectedSpApiKeys"
                class="list-table"
              >
                <el-table-column :label="__('API Key')">
                  <template slot-scope="scope">
                    <span>{{ scope.row.api_key }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-scrollbar>
        </el-tab-pane>
        <el-tab-pane
          :label="__('Task Template Libraries')"
          name="template-library"
        >
          <el-scrollbar :native="true">
            <div class="contentListContainer">
              <el-table
                ref="acPlanTable"
                v-loading="isLoading"
                :data="templates"
                class="list-table"
              >
                <el-table-column :label="__('Template Name')">
                  <template slot-scope="scope">
                    <span>{{ scope.row.template_name }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="__('Task Type')">
                  <template slot-scope="scope">
                    <span>{{ scope.row.task_type }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="__('Tags')">
                  <template slot-scope="scope">
                    <span>{{ scope.row.tags }}</span>
                  </template>
                </el-table-column>
                <el-table-column :label="__('Publisher')">
                  <template slot-scope="scope">
                    <span>{{ scope.row.publisher }}</span>
                  </template>
                </el-table-column>
              </el-table>
            </div>
          </el-scrollbar>
        </el-tab-pane>
      </el-tabs>
    </div>
    <el-row type="flex" style="margin-top: 30px; padding-left: 0.7rem">
      <el-col :span="12" :offset="0">
        <el-button
          type="primary"
          class="submitBtn"
          @click="submitForm"
          :loading="isSubmitting"
          >Move
        </el-button>
      </el-col>
    </el-row>
  </div>
</template>

<script>
// import PageHeader from "@/components/PageHeader";
import { mapActions, mapState } from "vuex";

export default {
  components: {},
  props: {},
  computed: {
    ...mapState("app", {
      selectedServiceProviderId: state => state.selectedServiceProviderId
    }),

    ...mapState("spplans", {
      spPlanLoading: state => state.isLoading,
      spAssignedPlans: state => state.spPlans
    }),

    ...mapState("acplans", {
      spPlanLoading: state => state.isLoading,
      acAssignedPlans: state => state.availablePlans
    }),

    ...mapState("serviceproviders", {
      serviceProviders: state => state.serviceProviders,
      selectedSpAccounts: state => state.selectedSpAccounts,
      selectedSpTrunks: state => state.selectedSpTrunks,
      selectedSpApiKeys: state => state.selectedSpApiKeys,
      spLoading: state => state.loading
    }),
    ...mapState("passwordpolicies", {
      policies: state => state.policies,
      loading: state => state.isLoading
    }),
    ...mapState("tasklibrary", {
      templates: state => state.templates
    })
  },
  data() {
    const checkFromSpId = (rule, value, callback) => {
      if (this.contentForm.sp_id === "") {
        callback(__("Service provider is required"));
      } else {
        callback();
      }
    };
    const checkToSpId = (rule, value, callback) => {
      if (this.contentForm.to_sp_id === "") {
        callback(__("Service provider is required"));
      } else {
        callback();
      }
    };
    const checkAcId = (rule, value, callback) => {
      if (this.contentForm.ac_id === "") {
        callback(__("Account is required"));
      } else {
        callback();
      }
    };
    return {
      contentForm: {
        sp_id: "",
        to_sp_id: "",
        ac_id: ""
      },
      isLoading: false,
      isSubmitting: false,
      activeSection: "ac-plans",
      rules: {
        sp_id: [
          {
            required: true,
            trigger: "blur",
            message: __("Service Provider is required"),
            validator: checkFromSpId
          }
        ],
        ac_id: [
          {
            required: true,
            trigger: "blur",
            message: __("Account is required"),
            validator: checkAcId
          }
        ],
        to_sp_id: [
          {
            required: true,
            trigger: "blur",
            message: __("Service Provider is required"),
            validator: checkToSpId
          }
        ]
      }
    };
  },
  methods: {
    ...mapActions("serviceproviders", {
      fetchAccountsForSelectedSPForMovingAccount:
        "fetchAccountsForSelectedSPForMovingAccount",
      fetchAccountsForSelectedSP: "fetchAccountsForSelectedSP",
      getServiceProviders: "getServiceProviders",
      fetchTasksForSelectedAc: "fetchTasksForSelectedAc",
      fetchTrunksForSelectedSP: "fetchTrunksForSelectedSP",
      getSpApiKeys: "getSpApiKeys",
      moveAccountToSp: "moveAccountToSp"
    }),
    ...mapActions("acplans", {
      getAcPlansForSelectedAccount: "getAcPlansForSelectedAccount",
      getAccountsForServiceProvider: "getAccountsForServiceProvider"
    }),
    ...mapActions("spplans", {
      getSpPlans: "getSpPlans"
    }),
    ...mapActions("passwordpolicies", {
      getPasswordPolicies: "getPasswordPolicies"
    }),
    ...mapActions("tasklibrary", {
      fetchTemplatesForSp: "fetchTemplatesForSp"
    }),

    async handleSpSelection(sp) {
      this.contentForm.ac_id = null;
      await this.fetchAccountsForSelectedSPForMovingAccount(sp);
      await this.getSpPlans({
        sp_id: this.contentForm.sp_id,
        plan_type: "all"
      });
      await this.getPasswordPolicies({ sp_id: this.contentForm.sp_id });
      await this.fetchTrunksForSelectedSP(sp);
      await this.getSpApiKeys({ sp_id: this.contentForm.sp_id });
      await this.fetchTemplatesForSp({ sp_id: this.contentForm.sp_id });
    },

    async handleToSpSelection(sp) {
      await this.fetchAccountsForSelectedSP(sp);
    },

    async handleAcSelection(ac) {
      console.log("Selected :", ac);
      await this.getAcPlansForSelectedAccount({
        ac_id: this.contentForm.ac_id,
        plan_type: "all"
      });
    },
    initContent() {
      this.contentForm.sp_id = "";
      this.contentForm.to_sp_id = "";
      this.contentForm.ac_id = "";
    },
    handleCancel() {
      this.initContent();
      this.$emit("cancel");
    },
    submitForm() {
      this.$refs.contentForm.validate(valid => {
        if (valid) {
          this.isSubmitting = true;
          this.moveAccountToSp(this.contentForm)
            .then(data => {
              this.isSubmitting = false;
              this.$message({
                name: "success",
                message: data.message
              });
            })
            .catch(err => {
              this.isSubmitting = false;
              this.$message({
                type: "error",
                message: err.response.data.message
              });
            });
        }
      });
    }
  }
};
</script>

<style scoped lang="scss">
$content-theme-color: var(--theme-color) !default;
$content-theme-hover-color: var(--theme-hover-color) !default;
$content-theme-row-hover-color: var(--theme-row-hover-color) !default;
$content-theme-outline-color: var(--theme-outline-color) !default;
@import "~@/styles/content-edit-info.scss";
@import "~@/styles/content-list.scss";
@import "~@/styles/tags.scss";
.item {
  margin: 4px;
}
::v-deep .el-radio {
  :hover {
    border-color: $content-theme-color;
  }
  .el-radio__input.is-checked .el-radio__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }
  .el-radio__input.is-checked + .el-radio__label {
    color: $content-theme-color;
  }
}
::v-deep .el-checkbox {
  :hover {
    border-color: $content-theme-color;
  }
  .el-checkbox__input.is-checked .el-checkbox__inner {
    border-color: $content-theme-color;
    background: $content-theme-color;
  }
  .el-checkbox__input.is-checked + .el-checkbox__label {
    color: $content-theme-color;
  }
}
</style>
